import React from 'react'
import { Composition } from 'atomic-layout'
// import { Text, Button, Image } from '../../atoms'
import { Flex,Input as MyInput, Label, Text, Box } from 'theme-ui';
import { useForm } from 'react-hook-form';
import MyButton from './Button';
import HypefastLogo from "../assets/hypefast.svg";
import { useMutation } from 'react-query';
import api from '../utils/api';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Toast from './Editor/Toast';

const areasMobile = `
  header
  email
  button
  footer
`

interface BrandData {
  email?: string;
}

const schema: Yup.SchemaOf<BrandData> = Yup.object({
  email: Yup.string().required('Email harus diisi!').email('Format email tidak valid'),
});

const ForgotPassword = () => {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  console.log({ errors });
  const onSuccess = ({ data }) => {
    navigate('/');
    Toast({
      title: 'Berhasil dikirim',
      message: 'Email berhasil dikirim',
      type: 'success',
    });
  };

  const onError = (error: any) => {
    if (error) {
      console.log(error);
      // const [message] = (error as ErrorResponse).messages;
      // setErrroMessage(message);
      // const email = document.querySelector('[name="email"]') as HTMLInputElement;
      // email?.focus();
    }
  };
  
  const {
    isLoading,
    mutate,
  } = useMutation((values: any) => api.forgotPassword(values), { onSuccess, onError });

  return (
    <Flex
      sx={{
        backgroundColor: '#f8f8f8',
        height: '100vh',
        alignItems: 'center',
        px: 5,
      }}
    >
      <Composition
        width={'100%'}
        inline
        // as={Container}
        areas={areasMobile}
        templateCols={'1fr'}
        gap={20}
        padding={32}
      >
        {({ Header, Email, Button, Footer }) => (
          <>
            <Header>
              <Box
                py={4}
              >
                <HypefastLogo
                  width={'100%'}
                />
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='headline'
                >
                  Don't Worry
                </Text>
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='body'
                >
                  We got your back, please enter your email address and we will send you the instructions to your email
                </Text>
              </Box>
            </Header>
            <Email>
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Email
                  </Text>
                </Label>
              </Box>
              <MyInput
                // sx={{
                //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                //   backgroundColor: '#d7cd74',
                // }}
                placeholder="Email"
                variant={errors.email && 'error' || 'input'}
                {...register("email")}
              />
              {errors.email && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.email.message}</Text>  
              )}
            </Email>
            <Button>
              <MyButton
                full
                isLoading={isLoading}
                variant='primary'
                sx={{
                  // backgroundColor: '#332f11',
                }}
                onClick={handleSubmit(mutate)}
              >
                <Text
                  variant='subtitle'
                  // sx={{
                  //   color: '#ffe07c'
                  // }}
                >
                  Submit
                </Text>
              </MyButton>
            </Button>
            <Footer>
              {/* <Button variant='primary'>Buy</Button> */}
            </Footer>
          </>
        )}
      </Composition>

    </Flex>
  )
}

export default ForgotPassword
