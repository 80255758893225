import * as React from 'react'
import ForgotPassword from '../components/ForgotPassword'
import Page from '../components/Layout/Page';


// markup
const ForgotPasswordPage = () => {
  return (
    <Page>
      <ForgotPassword/>
    </Page>
  )
}

export default ForgotPasswordPage
